









































































































import { Getter, Action } from "vuex-class";
import { Component, Vue, Watch, Ref } from "vue-property-decorator";
import DotCard from "@/components/dots/card.vue";
import ShareLink from "@/components/general/share.vue";
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { FormBase, FormError, FormSubmit } from "@/components/forms";

@Component({
  components: {
    Avatar: () => import("@/components/general/avatar.vue"),
    Comments: () => import("@/components/general/comments.vue"),
    DropdownOptions: () => import("@/components/general/dropdownOptions.vue"),
    ModalDotCreate: () => import("@/components/dots/modals/create.vue"),
    DotCard,
    EditorContent,
    BubbleMenu,
    FormBase,
    FormError,
    FormSubmit,
    ShareLink,
  },
})
export default class PageHunchDetail extends Vue {
  @Getter("workspace/viewing") workspace!: Workspace;

  @Getter("hunch/viewing") hunch!: Hunch;

  @Action("hunch/delete") deleteHunch!: (payload: { id: number }) => Promise<void>;

  @Action("hunch/update") updateHunch!: (payload: { id: number; payload: HunchUpdatePayload }) => Promise<Hunch>;

  @Action("dot/index") indexDots!: (payload: SearchIndexPayload) => Promise<Dot[]>;

  @Ref() dropdown!: {
    open: boolean;
  };

  @Ref() form!: FormClass;

  comments: Comment[] = [];

  showCreateDot = false;

  dots: Dot[] = [];

  edit = false;

  editor: Editor | null = null;

  payload: HunchUpdatePayload = {
    description: "",
  };

  errorResponse: ErrorResponse = {
    status: 0,
    errors: [],
  };

  mounted() {
    this.initDots();
  }

  private initDots() {
    if (this.hunch.dot_ids.length === 0) {
      return;
    }

    const payload: SearchIndexPayload = {
      per_page: 4,
      s: { created_at: "desc", id: "desc" },
      q: {
        and: {
          workspace_id: `${this.workspace.id}`,
          or: {
            id: this.hunch.dot_ids,
          },
        },
      },
    };

    this.indexDots(payload).then((dots: Dot[]) => (this.dots = dots));
  }

  private handelDeleteHunch() {
    this.deleteHunch({ id: this.hunch.id }).then(() => {
      this.$router.push({ name: "hunches" });
    });
  }

  private handleUpdateHunch() {
    this.updateHunch({ id: this.hunch.id, payload: this.payload })
      .then(() => {
        this.edit = false;
      })
      .catch((errorResponse: ErrorResponse) => (this.errorResponse = errorResponse))
      .finally(() => this.form.reset());
  }

  private handleEditHunch() {
    this.edit = true;
    this.dropdown.open = false;
  }

  @Watch("edit")
  handleEditChanged(value: boolean) {
    if (value) {
      this.editor = new Editor({
        content: this.hunch.description,
        extensions: [StarterKit],
        editorProps: {
          attributes: {
            class: "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl m-5 focus:outline-none min-h-[350px]",
          },
        },
        onUpdate: () => {
          if (this.editor) {
            this.payload.description = this.editor.getHTML();
          }
        },
      });
      this.editor.commands.focus("start");
    } else {
      if (this.editor) {
        this.editor.destroy();
      }
    }
  }
}
