


















import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import { FormInput } from "@/components/forms";

@Component({
  components: {
    LayoutModal: () => import("@/layouts/components/modal.vue"),
    FormInput,
  },
})
export default class GeneralShare extends Vue {
  @Prop() type!: "dot" | "hunch";

  @Prop() link!: string;

  @Ref() input!: {
    $refs: {
      input: HTMLInputElement;
    };
  };

  open = false;

  payload = {
    link: "",
  };

  private copyUrl() {
    this.input.$refs.input.select();
    this.input.$refs.input.setSelectionRange(0, 99999);
    document.execCommand("copy");
  }

  get baseLink() {
    return process.env.VUE_APP_WEB_URL + this.link;
  }
}
